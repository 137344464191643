import { FormattedMessage, useIntl } from 'react-intl';
import RadioButton from '@rio-cloud/rio-uikit/lib/es/RadioButton';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import { isWorkshopParentFrontend } from '../../config';
import FeedbackReceived from './FeedbackReceived';
import { useSendFeedbackMutation } from './api/feedbackSlice';
import classNames from 'classnames';

// Constants
const FEEDBACK_ORIGIN_CUSTOMER = 'CUSTOMER';
const FEEDBACK_ORIGIN_WORKSHOP = 'WORKSHOP';
const FEEDBACK_ORIGIN_WORKSHOP_ENGINE_OIL = 'WORKSHOP_ENGINE_OIL';
const FEEDBACK_ORIGIN_CUSTOMER_ENGINE_OIL = 'CUSTOMER_ENGINE_OIL';

// configuration of feedback form
const STARTS_COUNT = 5;
const STARTS_WIDTH = 50;
const TOTAL_WORD_COUNT = 3000;
const TEXT_AREA_ROWS = 4;

export const getFeedbackOrigin = () => {
    const currentUrl = new URL(window.location.href);
    return currentUrl.searchParams.get('feedback_origin');
};

export type RatingStarsLiProps = {
    moduleName: string;
    required: boolean;
    rating: number | undefined;
    setRating: Function;
    showError: boolean | undefined;
};

const RatingStarsLi = (props: RatingStarsLiProps) => {
    const { moduleName, required, rating, setRating, showError } = props;
    return (
        <li>
            <FormattedMessage
                id={'feedbackcare.info.feedback.overall_support'}
                values={{
                    b: (innerText) => <b>{innerText}</b>,
                    moduleName,
                }}
            />
            &nbsp;
            <FormattedMessage
                id={required ? 'feedbackcare.info.feedback.required' : 'feedbackcare.info.feedback.optional'}
            />
            {required && showError && rating === undefined && (
                <i className="text-color-danger">
                    <span className="margin-right-5 margin-left-5 rioglyph rioglyph-warning-sign" />
                    <FormattedMessage id="feedbackcare.info.feedback.mandatory_field" />
                </i>
            )}
            <ReactStars
                count={STARTS_COUNT}
                onChange={(newRating: number) => {
                    setRating(newRating);
                }}
                size={STARTS_WIDTH}
                activeColor="#fccc7c"
                color="#ffffff"
            />
        </li>
    );
};

export type ExplanationTextAreaLiProps = {
    text: string | undefined;
    setText: Function;
};

const ExplanationTextAreaLi = (props: ExplanationTextAreaLiProps) => {
    const { text, setText } = props;
    const [wordCount, setWordCount] = useState<number>(0);
    const handleTextChange = (event: { target: { value: string } }) => {
        const value = event.target.value;
        const currentWordCount = value.length;
        setText(value);
        setWordCount(currentWordCount);
    };
    return (
        <li className="margin-bottom-10 form-group has-feedback">
            <FormattedMessage id={'feedbackcare.info.feedback.other_feedback'} />
            <textarea
                className="form-control max-height-200 margin-top-10"
                value={text}
                rows={TEXT_AREA_ROWS}
                maxLength={TOTAL_WORD_COUNT}
                onChange={handleTextChange.bind(null)}
            />
            <span className={`help-block ${wordCount === TOTAL_WORD_COUNT ? 'text-color-danger' : ''}`}>
                {wordCount}/{TOTAL_WORD_COUNT}
            </span>
        </li>
    );
};

export type WillingToBeContactedLiProps = {
    willingToBeContacted: boolean | undefined;
    setWillingToBeContacted: Function;
    showError: boolean | undefined;
};

const WillingToBeContactedLi = (props: WillingToBeContactedLiProps) => {
    const { willingToBeContacted, setWillingToBeContacted, showError } = props;
    const handleContact = (contactable: boolean) => {
        setWillingToBeContacted(contactable);
    };
    return (
        <>
            <li className="margin-top-20 margin-bottom-10">
                <FormattedMessage id="feedbackcare.info.feedback.contact" />
                {showError && willingToBeContacted === undefined && (
                    <i className="text-color-danger">
                        <span className="margin-right-5 margin-left-5 rioglyph rioglyph-warning-sign" />
                        <FormattedMessage id="feedbackcare.info.feedback.mandatory_field" />
                    </i>
                )}
            </li>
            <RadioButton
                name="ContactRadios"
                onChange={handleContact.bind(null, true)}
                inline
                label={<FormattedMessage id="feedbackcare.info.feedback.contact_yes" />}
                checked={!!willingToBeContacted}
            />
            <RadioButton
                name="ContactRadios"
                onChange={handleContact.bind(null, false)}
                inline
                label={<FormattedMessage id="feedbackcare.info.feedback.contact_no" />}
                checked={willingToBeContacted === false}
            />
        </>
    );
};

export type ExpanderPanelWithRatingAndExplanationProps = {
    expanderTitle: string;
    moduleName: string;
    ratingRequired: boolean;
    rating: number | undefined;
    setRating: Function;
    showError: boolean | undefined;
    text: string | undefined;
    setText: Function;
    showExpander: boolean;
    isExpanded: boolean;
};

const ExpanderPanelWithRatingAndExplanation = (props: ExpanderPanelWithRatingAndExplanationProps) => {
    const {
        expanderTitle,
        moduleName,
        ratingRequired,
        rating,
        setRating,
        showError,
        text,
        setText,
        showExpander,
        isExpanded,
    } = props;
    const ratingAndExplanation = (
        <ul className="padding-top-15 padding-left-15 padding-right-15">
            <RatingStarsLi
                moduleName={moduleName}
                required={ratingRequired}
                rating={rating}
                setRating={setRating}
                showError={showError}
            />
            <ExplanationTextAreaLi text={text} setText={setText} />
        </ul>
    );
    if (showExpander) {
        return (
            <ExpanderPanel
                open={isExpanded}
                title={expanderTitle}
                iconLeft
                bsStyle="separator"
                className="padding-top-15 margin-bottom-0"
            >
                <div className="padding-left-15">{ratingAndExplanation}</div>
            </ExpanderPanel>
        );
    }
    return ratingAndExplanation;
};

const Feedback = () => {
    const isWorkshopParentFE = isWorkshopParentFrontend();
    const intl = useIntl();

    const [ratingGeneral, setRatingGeneral] = useState<number>();
    const [textGeneral, setTextGeneral] = useState<string>();
    const [ratingEngineOil, setRatingEngineOil] = useState<number>();
    const [textEngineOil, setTextEngineOil] = useState<string>();
    const [willingToBeContacted, setWillingToBeContacted] = useState<boolean>();

    const [showError, setShowError] = useState<boolean>();
    const [sendFeedback, { isLoading, error, isSuccess }] = useSendFeedbackMutation();

    const origin = getFeedbackOrigin();
    const isFeedbackGeneral = origin === FEEDBACK_ORIGIN_CUSTOMER || origin === FEEDBACK_ORIGIN_WORKSHOP;
    const isFeedbackEngineOil =
        origin === FEEDBACK_ORIGIN_WORKSHOP_ENGINE_OIL || origin === FEEDBACK_ORIGIN_CUSTOMER_ENGINE_OIL;

    const handleFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault();

        if (isLoading) return;
        if (
            (ratingGeneral === undefined && isFeedbackGeneral) ||
            (ratingEngineOil === undefined && isFeedbackEngineOil) ||
            willingToBeContacted === undefined ||
            error
        ) {
            setShowError(true);
            return;
        }

        if (ratingGeneral) {
            sendFeedback({
                rating: ratingGeneral,
                comment: textGeneral === '' ? undefined : textGeneral,
                willing_to_be_contacted: willingToBeContacted,
                origin: isWorkshopParentFE ? FEEDBACK_ORIGIN_WORKSHOP : FEEDBACK_ORIGIN_CUSTOMER,
            });
        }
        if (ratingEngineOil) {
            sendFeedback({
                rating: ratingEngineOil,
                comment: textEngineOil === '' ? undefined : textEngineOil,
                willing_to_be_contacted: willingToBeContacted,
                origin: isWorkshopParentFE ? FEEDBACK_ORIGIN_WORKSHOP_ENGINE_OIL : FEEDBACK_ORIGIN_CUSTOMER_ENGINE_OIL,
            });
        }
    };

    if (isSuccess) {
        return <FeedbackReceived />;
    }

    const backgroundOpacity = isLoading ? classNames('opacity-50') : '';

    return (
        <>
            <div className={backgroundOpacity}>
                <div>
                    <FormattedMessage
                        id="feedbackcare.info.feedback.appreciate"
                        values={{
                            linebreak: <br />,
                            moduleName: <FormattedMessage id="feedbackcare.global.module_name" />,
                        }}
                    />
                </div>

                <form onSubmit={!isLoading ? handleFormSubmit : undefined} id="feedbackForm">
                    <ExpanderPanelWithRatingAndExplanation
                        expanderTitle={intl.formatMessage({ id: 'feedbackcare.expander.title.general_feedback' })}
                        moduleName={intl.formatMessage({ id: 'feedbackcare.global.module_name' })}
                        ratingRequired={isFeedbackGeneral}
                        rating={ratingGeneral}
                        setRating={setRatingGeneral}
                        showError={showError}
                        text={textGeneral}
                        setText={setTextGeneral}
                        showExpander
                        isExpanded={isFeedbackGeneral}
                    />
                    <ExpanderPanelWithRatingAndExplanation
                        expanderTitle={intl.formatMessage({ id: 'feedbackcare.expander.title.engine_oil' })}
                        moduleName={intl.formatMessage({ id: 'feedbackcare.expander.title.engine_oil' })}
                        ratingRequired={isFeedbackEngineOil}
                        rating={ratingEngineOil}
                        setRating={setRatingEngineOil}
                        showError={showError}
                        text={textEngineOil}
                        setText={setTextEngineOil}
                        showExpander
                        isExpanded={isFeedbackEngineOil}
                    />
                    <hr />
                    <ul className="padding-left-15 padding-right-15 padding-bottom-15">
                        <WillingToBeContactedLi
                            willingToBeContacted={willingToBeContacted}
                            setWillingToBeContacted={setWillingToBeContacted}
                            showError={showError}
                        />
                    </ul>
                    <button
                        className={`btn btn-primary float-right ${isLoading ? 'btn-loading-overlay' : ''}`}
                        form="feedbackForm"
                        type="submit"
                    >
                        <FormattedMessage id="feedbackcare.info.feedback.user_action.send_feedback" />
                    </button>
                </form>
            </div>
        </>
    );
};

export default Feedback;
