import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../../../configuration/tokenHandling/prepareHeaders';
import { ExternalFeedback } from '../../../generated/GeneratedApiTypes';

export const feedbackApi = createApi({
    reducerPath: 'feedbackApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${import.meta.env.VITE_BACKEND_URI}`, prepareHeaders }),
    endpoints: (builder) => ({
        sendFeedback: builder.mutation({
            query: (body: ExternalFeedback) => ({
                url: '/feedback',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useSendFeedbackMutation } = feedbackApi;
