import { Suspense } from 'react';
import { LoadingIndicator } from '../features/app/LoadingIndicator';
import Feedback from '../features/feedback/Feedback';

const FeedbackPage = () => {
    return (
        <div className={'overflow-hidden display-flex flex-column'}>
            <Suspense fallback={<LoadingIndicator />}>
                <Feedback />
            </Suspense>
        </div>
    );
};

export default FeedbackPage;
