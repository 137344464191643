import { createHashRouter, createRoutesFromElements, Route } from 'react-router-dom';
import App from '../features/app/App';
import { ErrorBoundary } from '../features/app/ErrorBoundary';
import FeedbackPage from '../pages/FeedbackPage';
import React from 'react';
import InternalErrorState from '../features/app/InternalErrorState';

export const GENERAL_ROUTE = '/feedback';
export const DEFAULT_ROUTE = '/error';
export const routes = [GENERAL_ROUTE, DEFAULT_ROUTE];

export const router = createHashRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route
                path={GENERAL_ROUTE}
                errorElement={
                    <ErrorBoundary>
                        <InternalErrorState />
                    </ErrorBoundary>
                }
                element={<FeedbackPage />}
            />
        </Route>,
    ),
);
